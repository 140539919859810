
import { Component, Vue } from 'vue-property-decorator';
import workspaceModule from '@/store/modules/workspaceModule';
import { getComponent } from '@/utils/helpers';
import scheduleModule from '@/store/modules/scheduleModule';
import accountModule from '@/store/modules/accountModule';

@Component({
  components: {
	WorkflowAutomationDialog: () => getComponent('tasqs/WorkflowAutomationDialog'),
  },
})
export default class WorkspacesNavbar extends Vue {

  createCustomAutomationPopup = false

  isSavingFromPopup = false

  dataLoading = false
  addChartPopupShowing = false
  showContentPopup = false

  showAddChartSelectionPopup = false
  newRigChartDetailsShowing = false
  newWellTestChartDetailsShowing = false

  showEditTableDropdown = false


  modifyTablePopup = false
  deleteTableInnerPopup = false
  editTableInnerPopup = false

  editTableNameInput = ''


//   templateFields = ["Well", "Assignee", "Vendor Details", "Checklist", "File"]

  get workspaces(): any[] {
	  return workspaceModule.activeWorkspaces
  }

  get tables(): any[] {
	  return workspaceModule.navBarOptions
  }

  get selectedTable() {
	  // @ts-ignore
	  if (workspaceModule.activeTable != null) {
		// @ts-ignore
		return workspaceModule.activeTable.ID
	  }
	return null
  }

  get tableEditDetails() {
	  return this.showEditTableDropdown
  }

  async editTablePopupApplyButtonTapped() {
	  this.isSavingFromPopup = true

	  await scheduleModule.updateTableName({
		  // @ts-ignore
		table_id: this.showEditTableDropdown.id,
		table_name: this.editTableNameInput
	  })
	//   this.isSavingFromPopup = false
	  window.location.reload();

  }

  async deleteTablePopupApplyButtonTapped() {
	  this.isSavingFromPopup = true
	  

		await scheduleModule.deleteTable({
			// @ts-ignore
			table_id: this.deleteTableInnerPopup.id,
		})
        location.reload();



  }
  

  editScheduleTitleSelected(table) {
	  if (this.showEditTableDropdown) {
		  this.showEditTableDropdown = false
	  } else {
		  this.showEditTableDropdown = table
	  }
  }

  editTableName() {
	  this.modifyTablePopup = true
	  this.editTableInnerPopup = true
	  // @ts-ignore
	  this.editTableNameInput = this.showEditTableDropdown.name

}
  deleteTable(table) {
	  this.modifyTablePopup = true
	  this.deleteTableInnerPopup = table

}

  async created() {


	  	// @ts-ignore
	
  }

  addNewTable() {
	  // emit
	  this.$emit('add-new-table');
  }

  selectAddViewOptionBack() {
	  // @ts-ignore
	  this.selectedAddView = null
  }




  goToTablePage(table) {
	  if (table.type == 'add_view') {
		  this.addNewTable()
		  return
	  }
	  
	// this.selectedWorkspace = workspace.ID
    this.$router.push({
      name: 'Workspaces',
      params: {
		  // @ts-ignore
        id: workspaceModule.activeWorkspace.ID,
		table_id: table.id
      },
    });
	// @ts-ignore
	workspaceModule.setActiveTable({id: table.id || ''});
  }

}
